@font-face { font-family: 'Roboto-Light'; src: url('/assets/Roboto-Light.ttf') format('truetype'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'Roboto-Regular'; src: url('/assets/Roboto-Regular.ttf') format('truetype'); font-weight: normal; font-style: normal; }

.uploadContent{
    height: 100px;
    border: 2px grey dashed;
    border-radius: 10px;
    position: relative;
  }
  
  .uploadContentText{
    font-size: 18px;
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: gray;
  }

  .evento{
    background-color: #116fbf !important;
  }
  
  .ausencia{
    background-color:#b94545 !important;
  }
  
  .aniversario{
    background-color:#95af23 !important;
  }
  
  .otros{
    background-color:#a3a3a3 !important;
  }
  
  .event-resumen{
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 2px 6px;  
    color: white;
  }
  
  .fc-today{
    background-color: #afafaf !important;
  }
  
  .fixed-label{
    top: -.75em !important;
    font-size: 12px;
  }

  .left-help-label{
    margin-left: 6px;
  }
  
  
  @media (min-width: 960px){
    .p-menubar-root-list{
      display: inherit !important;
     }
     
     .p-menubar .p-menuitem{
       display: inline-block;
     }
  }
  
  
  .p-hidden{
    display: none !important;
  }
  
  .alert{
    color:red;
    font-weight: bold;
  }
  
  .menu-item-warn{
    background-color: #ff9800;
  }
  
  .menu-item-warn:hover{
    background: #ff5757;
  }
  
  .p-inputswitch.p-disabled .p-inputswitch-slider{
  background: #acacac;
  }

  .card-header{
    background: none;
  }

  .ql-tooltip.ql-editing{
    left: 0px !important;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list{
    width: 200px;
  }

  .background-green-50{
    background-color: var(--green-50);
  }

  .background-red-50{
    background-color: var(--red-50);
  }

  .p-multiselect-label{
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }