.login{
  background-color: rgb(15, 105, 196);
  border-bottom-color: rgb(15, 105, 196);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(15, 105, 196);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(15, 105, 196);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(15, 105, 196);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(255, 255, 255) !important;
  cursor: pointer;
  display: block;
  font-family: "Roboto" , "Helvetica-Neue", "Roboto-Light", sans-serif !important;;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-weight: bolder;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  text-align: center;
  text-decoration: none rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-property: color, background-color, border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 180px;
  height: 48px;
  margin: auto;
  font-weight: bold;
}

.login:hover{
  background-color: #0B4E91 !important;
  background: #0B4E91 !important;
  border-color: #0B4E91 !important;
}

.login:active{
  background-color: #07305A !important;
}

.ico-gob{
  background-image: url("/assets/img/claveunica.svg");
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: left;
  vertical-align: middle;
  width: 24px;
}
